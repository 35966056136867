import { graphql, Link, StaticQuery } from "gatsby"
import React, { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import LazyLoad from "react-lazyload"
import Tilt from "react-parallax-tilt"
import Fade from "react-reveal/Fade"
import { Link as ScrollLink } from "react-scroll"
import CustomAccordion from "../../components/common/accordian"
import Buttons from "../../components/common/button"
import CarouselComponent from "../../components/common/carousel-component"
import AwardsFormBuilder from "../../components/common/FormBuilder/Awards-form-builder"
import Frame from "../../components/common/frame"
import SVGIcon from "../../components/common/SVGIcon"
import Navigation from "../../components/navigation"
import "../../styles/component/faq.scss"
import "../../styles/component/pricing-faq.scss"
import "../../styles/pages/mspawards.scss"

const Awards2024 = () => {
  const [submitSuccess, setSubmitSuccess] = useState(true)

  const nominateFormdata = [
    {
      name: "firstname",
      type: "text",
      label: "Your name",
      errorMessage: "Please enter a valid name",
    },
    {
      name: "email",
      type: "email",
      label: "Your email",
      errorMessage: "Please enter a valid email",
    },
    {
      name: "website",
      validateEmpty: "true",
      type: "text",
      label: "Your MSP's website",
      errorMessage: "Please enter website URL",
    },
    {
      name: "awards_message",
      type: "checkbox",
      choices: [
        "Emerging MSP of the year",
        "Vertical MSP of the year",
        "Solo MSP of the year",
        "Best Social Impact by an MSP",
        "The customer experience champion",
        "MSP with the best work culture",
        "MSP with the best social media presence",
        "MSP with the best use of automation",
        "MSP comeback of the year",
        "MSP Leader of the Year (Female)",
      ],
      placeholders: [
        "Highlight your MSP's remarkable growth journey",
        "Tell us how your MSP specializes in specific industries, providing tailored solutions and expertise",
        "Showcase how you manage and grow your MSP as a solo operator",
        "Describe how your MSP has made a positive community or environmental impact",
        "Highlight with example(s) how your MSP excels in providing customer service",
        "Describe the work environment and initiatives that set your MSP apart",
        "Share how your MSP effectively engages its audience on social media and a link to your social media handle",
        "Explain how your MSP uses automation to boost efficiency and service",
        "Highlight how your MSP overcame 2023’s challenges and created a turnaround in growth this year",
        "Share how your leadership has driven growth, inspired teams, and made a significant impact in your MSP",
      ],
    },
  ]

  const handleAfterSubmit = () => {
    setSubmitSuccess(true)
  }

  const responsiveSpeaker = {
    mobile2: {
      breakpoint: { max: 535, min: 480 },
      items: 1.5,
    },
    mobile3: {
      breakpoint: { max: 480, min: 460 },
      items: 1.4,
    },
    mobile5: {
      breakpoint: { max: 460, min: 435 },
      items: 1.35,
    },
    mobile4: {
      breakpoint: { max: 435, min: 0 },
      items: 1.2,
    },
  }

  const InfoBox = props => {
    const { info, heading } = props
    return info.map((el, idx) => {
      return (
        <>
          <Col
            lg={3}
            className={`d-dsk ${idx == 2 ? "" : "info-border-box prelative"}`}
          >
            <div className="info-box">
              <div className="p14">
                <p className="fw-bolder">{el}</p>
              </div>
              <div className="p16">
                <p>{heading[idx]}</p>
              </div>
            </div>
          </Col>
        </>
      )
    })
  }

  const SpeakerCards = props => {
    const { title, subtext, image } = props
    return (
      <>
        <Col lg={3} xs={12}>
          <Tilt
            className="parallax-effect-glare-scale"
            perspective={800}
            scale={1.02}
          >
            <div className="speaker-card">
              <div className="speaker-image">
                <LazyLoad throttle={200} height={200}>
                  <img src={image?.url} alt="speaker" />
                </LazyLoad>
              </div>

              <p className="title fw-bold">{title}</p>

              <p className="subtext">{subtext}</p>
            </div>
          </Tilt>
        </Col>
      </>
    )
  }

  return (
    <div>
      <StaticQuery
        query={graphql`
          query Awards2024 {
            SuperOps {
              pageComponents(where: { pageName: "MSP AWARDS 2024" }) {
                hero {
                  tag
                  tagLink
                  heroExcerptList
                  animAltText
                }
                seo {
                  keywords
                  description
                  title
                  image {
                    id
                    url
                  }
                }
                sectionName
                pageContents {
                  heading
                  subtext {
                    html
                  }
                  detailIcon {
                    url
                  }
                  detailContent
                  detailHeading
                }
                timeline {
                  time
                  agenda
                  title
                  speakerName
                  speakerTitle
                  speaker {
                    url
                  }
                }
                links {
                  linkName
                  icon {
                    url
                  }
                  authorName
                }
                faqs {
                  category
                  question
                  answerrt {
                    html
                  }
                }
                card {
                  cardName
                  image {
                    url
                  }
                  title
                  subtext
                }
                images {
                  url
                }
              }
            }
          }
        `}
        render={data => (
          <>
            {data.SuperOps.pageComponents.map(page => {
              const { hero, seo, pageContents, faqs, card } = page
              const category = pageContents[0]
              const reason = pageContents[1]
              const purpose = pageContents[2]
              const dates = pageContents[3]
              let delayCount
              return (
                <div className="mspAwards-2023 bg-dark">
                  <Frame
                    seo={seo}
                    FooterName="Super-Summit"
                    footerClassName="super-summit pt-0"
                    twitterOG
                  >
                    <header>
                      <Navigation
                        page="Supersummit-2022"
                        tabs={[
                          { name: "categories", id: "categories_id" },
                          { name: "why participate", id: "why_participate_id" },
                          { name: "jury", id: "jury_id" },
                          { name: "faq", id: "faq_id" },
                        ]}
                        transparentScroll
                      />
                    </header>

                    <div className="msp-container">
                      <section className="Hero position-relative">
                        <video
                          className="bg-video d-dsk"
                          autoPlay
                          muted
                          loop
                          id="bg-video"
                        >
                          <source
                            src={
                              "https://us-west-2.graphassets.com/AsRMKMrtKTFW6TGbr4KgUz/I5V3nnThRmWrRag5eNoE"
                            }
                            type="video/mp4"
                          />
                        </video>
                        <div className="gradient"></div>
                        <Container>
                          <div className="wrapper position-relative">
                            <iframe
                              src={hero.tagLink}
                              className="award-coin"
                            ></iframe>
                            <h1 className="hero-text fw-bold font-roboto down-to-up-2">
                              {hero.tag}
                            </h1>
                            <div className="d-flex justify-content-center flex-wrap regis-deadline down-to-up-3">
                              {dates.detailHeading.map((item, idx) => {
                                return (
                                  <div>
                                    <p className="p14 tag">{item}</p>
                                    <div className="d-flex p24 justify-content-center calendar-wrap">
                                      <SVGIcon name="mspawards/calendarPink" />
                                      <p className="date p20">
                                        {dates.detailContent[idx]}
                                      </p>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </Container>
                      </section>
                      <div className="main-wrapper">
                        <section className="info-section">
                          <Container>
                            <center>
                              <Row className="justify-content-around info-wrap down-to-up-4">
                                <InfoBox
                                  info={hero.heroExcerptList}
                                  heading={hero.animAltText}
                                />
                                <div
                                  className={`d-mob d-flex p-0 mob-info-border position-relative`}
                                >
                                  <div className="info-box w-50 mobile-info-border prelative">
                                    <div className="p14">
                                      <p className="fw-bolder">
                                        {hero.heroExcerptList[0]}
                                      </p>
                                    </div>
                                    <div className="p16">
                                      <p>{hero.animAltText[0]}</p>
                                    </div>
                                  </div>
                                  <div className="info-box w-50">
                                    <div className="p14">
                                      <p className="fw-bolder">
                                        {hero.heroExcerptList[1]}
                                      </p>
                                    </div>
                                    <div className="p16">
                                      <p>{hero.animAltText[1]}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="info-box d-mob">
                                  <div className="p14">
                                    <p className="fw-bolder">
                                      {hero.heroExcerptList[2]}
                                    </p>
                                  </div>
                                  <div className="p16">
                                    <p>{hero.animAltText[2]}</p>
                                  </div>
                                </div>
                              </Row>
                              <ScrollLink to="nominate_form_id">
                                <Buttons
                                  theme="primary-new register-button down-to-up-5"
                                  text="NOMINATE NOW"
                                />
                              </ScrollLink>
                            </center>
                          </Container>
                          <div id="categories_id"></div>
                        </section>

                        <section className="category ptb25">
                          <Container className="Layout-container">
                            <div className="category-wrapper mx-auto mb90">
                              <Fade bottom distance="20px">
                                <div className="d-flex prelative align-items-end altv3 flex-wrap">
                                  <Col lg={4}>
                                    <h1 className="category-heading fw-bold m-0 font-roboto">
                                      {category.heading}
                                    </h1>
                                  </Col>
                                  <Col lg={8} className="underline-wrapper">
                                    <div className="underlineGradient"></div>
                                  </Col>
                                </div>
                              </Fade>
                            </div>
                            <Row className="category-cards d-flex flex-wrap d-dsk">
                              {category.detailHeading.map((item, ind) => {
                                if (delayCount > 5) {
                                  delayCount = 0
                                }
                                if (item === "button") {
                                  return (
                                    <Col
                                      lg={3}
                                      className="d-flex align-items-center justify-content-end d-dsk"
                                    >
                                      <Fade bottom distance="20px">
                                        <ScrollLink to="nominate_form_id">
                                          <Buttons
                                            theme="primary-new register-button"
                                            text="NOMINATE NOW"
                                          />
                                        </ScrollLink>
                                      </Fade>
                                    </Col>
                                  )
                                } else {
                                  return (
                                    <Col lg={3} className="altv3">
                                      <Fade
                                        bottom
                                        delay={(delayCount + 1) * 130}
                                        duration="800"
                                        distance={"20px"}
                                      >
                                        <span className="dspnone">
                                          {delayCount++}
                                        </span>
                                        <div className="category-card br10">
                                          <div className="position-relative">
                                            <h1 className="font-roboto number fw-bold">
                                              {ind + 1 < 10 ? "0" : ""}
                                              {ind + 1}
                                            </h1>
                                            <SVGIcon
                                              name="mspawards/isolationMode"
                                              className="position-absolute isolation-mode"
                                            />
                                          </div>

                                          <p className="title fw-bold m-0">
                                            {item}
                                          </p>
                                        </div>
                                      </Fade>
                                    </Col>
                                  )
                                }
                              })}
                            </Row>
                          </Container>

                          <Container className="d-mob caros">
                            <Row>
                              <CarouselComponent
                                status
                                swipeable
                                responsive={responsiveSpeaker}
                                autoPlay={false}
                              >
                                {category.detailHeading
                                  .slice(0, category.detailHeading.length - 1)
                                  .map((item, ind) => {
                                    return (
                                      <div className="category-card br10 altv3">
                                        <div className="position-relative">
                                          <h1 className="font-roboto number fw-bold">
                                            {ind + 1 < 10 ? "0" : ""}
                                            {ind + 1}
                                          </h1>
                                          <SVGIcon
                                            name="mspawards/isolationMode"
                                            className="position-absolute isolation-mode"
                                          />
                                        </div>

                                        <p className="title fw-bold m-0">
                                          {item}
                                        </p>
                                      </div>
                                    )
                                  })}
                              </CarouselComponent>
                            </Row>
                          </Container>

                          <Container className="d-mob">
                            <div className="categoryButton d-flex justify-content-center">
                              <Fade bottom distance="20px">
                                <ScrollLink to="nominate_form_id">
                                  <Buttons
                                    theme="primary-new register-button"
                                    text="NOMINATE NOW"
                                  />
                                </ScrollLink>
                              </Fade>
                            </div>
                          </Container>
                        </section>

                        <section className="reasons ptb25">
                          <Container className="Layout-container">
                            <Col
                              lg={10}
                              className="reason-wrapper mx-auto mb90"
                            >
                              <Fade bottom distance="20px">
                                <div className="d-flex prelative align-items-end altv3 flex-wrap">
                                  <Col lg={4}>
                                    <h1 className="reason-heading fw-bold m-0 font-roboto">
                                      {reason.heading}
                                    </h1>
                                  </Col>
                                  <Col lg={8} className="underline-wrapper">
                                    <div className="underlineGradient"></div>
                                  </Col>
                                </div>
                              </Fade>
                              <div className="d-flex flex-wrap inner-wrapper first mx-auto mt60">
                                <Row>
                                  {reason.detailHeading.map((item, idx) => {
                                    return (
                                      <Col lg={4}>
                                        <Fade bottom distance="20px">
                                          <SVGIcon
                                            name="mspawards/orangeTick"
                                            className="mb8 orange-tick"
                                          />
                                          <p className="text fw-500 m-0">
                                            {item}
                                          </p>
                                        </Fade>
                                      </Col>
                                    )
                                  })}
                                </Row>
                              </div>
                              <div className="inner-wrapper second mx-auto mt80 d-flex align-items-center justify-content-between position-relative">
                                <div className="d-flex flex-column gap-24">
                                  {reason.detailContent.map(item => {
                                    return (
                                      <Fade bottom distance="20px">
                                        <p className="p18 content m-0">
                                          {item}
                                        </p>
                                      </Fade>
                                    )
                                  })}
                                </div>
                                <ScrollLink to="nominate_form_id">
                                  <Fade bottom distance="20px">
                                    <Buttons
                                      onClick={() => setSubmitSuccess(false)}
                                      theme={"primary-new register-button"}
                                      text={"NOMINATE NOW"}
                                    />
                                  </Fade>
                                </ScrollLink>
                              </div>
                            </Col>
                            <div id="why_participate_id"></div>
                          </Container>
                        </section>

                        <section className="purpose ptb25">
                          <Container className="Layout-container">
                            <Col lg={10} className="purpose-wrapper mx-auto">
                              <Fade bottom distance="20px">
                                <div className="d-flex prelative align-items-end altv3 mb90 flex-wrap">
                                  <Col lg={4}>
                                    <h1 className="purpose-heading fw-bold m-0 font-roboto">
                                      {purpose.heading}
                                    </h1>
                                  </Col>
                                  <Col lg={8} className="underline-wrapper">
                                    <div className="underlineGradient"></div>
                                  </Col>
                                </div>
                              </Fade>
                              {purpose.detailHeading.map((el, index) => {
                                return (
                                  <Fade bottom distance="20px">
                                    <div className="d-flex content-purpose">
                                      <Col lg={4} className="p16">
                                        <p className="sub-heading fw-bold font-roboto">
                                          {el}
                                        </p>
                                      </Col>
                                      <Col lg={8}>
                                        <p className="content">
                                          {purpose.detailContent[index]}
                                        </p>
                                      </Col>
                                    </div>
                                  </Fade>
                                )
                              })}
                              <div id="nominate_form_id"></div>
                            </Col>
                          </Container>
                        </section>

                        <section className="nominate-form ptb25">
                          <Container className="Layout-container">
                            <Col lg={10} className="nominate-wrapper mx-auto">
                              <Fade bottom distance="20px">
                                <div className="d-flex prelative align-items-end altv3 mb90 flex-wrap">
                                  <Col lg={3}>
                                    <h1 className="nominate-heading fw-bold m-0 font-roboto">
                                      Nominate
                                    </h1>
                                  </Col>
                                  <Col lg={9} className="underline-wrapper">
                                    <div className="underlineGradient"></div>
                                  </Col>
                                </div>
                              </Fade>
                              <div className="form-wrapper d-flex align-items-center flex-column justify-content-center">
                                {submitSuccess ? (
                                  <>
                                    <div className="form-closed mx-auto">
                                      <p className="message">
                                        Registrations for the SuperOps MSP
                                        awards has now closed. In the meantime,
                                        please consider following us on{" "}
                                        <a
                                          href="https://www.linkedin.com/company/superops-ai/mycompany/"
                                          target="_blank"
                                          className="link"
                                        >
                                          LinkedIn
                                        </a>
                                      </p>
                                    </div>
                                    {/* <div className="success-box">
                                      <center>
                                        <img
                                          src={NominateFormSucess}
                                          alt="nominate-logo"
                                          width="138px"
                                          className="nominate-logo"
                                        />
                                        <p className="first font-roboto">
                                          Woohoo! You’re successfully
                                          registered!
                                        </p>
                                        <p className="second">
                                          Join us live for the winner
                                          announcement on December 19 - Save the
                                          date -{" "}
                                          <a
                                            href="https://us-west-2.graphassets.com/AsRMKMrtKTFW6TGbr4KgUz/CEQL3fnbTCC0Zmen80Sr"
                                            download
                                          >
                                            <span className="download-tag">
                                              Download ICS
                                            </span>
                                          </a>
                                        </p>
                                        <Buttons
                                          onClick={() =>
                                            setSubmitSuccess(false)
                                          }
                                          theme={"primary-new register-button"}
                                          text={"NOMINATE AGAIN"}
                                        />
                                      </center>
                                    </div> */}
                                  </>
                                ) : (
                                  <>
                                    <Fade bottom distance="20px">
                                      <AwardsFormBuilder
                                        formClassName="forms w-100"
                                        errorClassName="error-message"
                                        inputErrorClassName="error-active"
                                        data={nominateFormdata}
                                        buttonText="NOMINATE NOW"
                                        buttonClassName="primary-new register-button"
                                        IpStackData
                                        endpoint={
                                          process.env
                                            .HUBSPOT_MSP_AWARDS_ENDPOINT
                                        }
                                        extradata={{
                                          name: "awards_year",
                                          value: "Year - 2024",
                                        }}
                                        url={process.env.HUBSPOT_MSP_AWARDS_URL}
                                        formName="MSP awards 2024 form - Superops"
                                        afterSubmit={handleAfterSubmit}
                                      />
                                      <div className="p12 form-footer">
                                        <p>
                                          We care about protecting your data.
                                          Here’s our{" "}
                                          <Link to="/privacy">
                                            Privacy Policy
                                          </Link>
                                        </p>
                                      </div>
                                    </Fade>
                                  </>
                                )}
                              </div>
                            </Col>
                          </Container>
                          <div id="jury_id"></div>
                        </section>

                        <section className="speakers ptb25 mb90">
                          <Container>
                            <Col lg={10} className="speaker-wrapper mx-auto">
                              <div className="d-flex align-items-end altv3 mb50 flex-wrap">
                                <Col lg={5}>
                                  <h1 className="speaker-heading fw-bold m-0 font-roboto">
                                    {card.cardName}
                                  </h1>
                                </Col>
                                <Col lg={7} className="underline-wrapper">
                                  <div className="underlineGradient"></div>
                                </Col>
                              </div>
                            </Col>

                            <div className="d-dsk">
                              <Row>
                                {card.title.map((el, idx) => {
                                  return (
                                    <SpeakerCards
                                      title={el}
                                      image={card.image[idx]}
                                      subtext={card.subtext[idx]}
                                    />
                                  )
                                })}
                              </Row>
                            </div>
                          </Container>

                          <Container className="d-mob caros">
                            <Row>
                              <CarouselComponent
                                status
                                swipeable
                                responsive={responsiveSpeaker}
                                autoPlay={false}
                              >
                                {card.title.map((el, idx) => {
                                  return (
                                    <SpeakerCards
                                      title={el}
                                      image={card.image[idx]}
                                      subtext={card.subtext[idx]}
                                    />
                                  )
                                })}
                              </CarouselComponent>
                            </Row>
                          </Container>
                          <div id="faq_id"></div>
                        </section>

                        <section className="faq mt100 mb90">
                          <Fade bottom distance="20px">
                            <Container className="Layout-container">
                              <Row className="justify-content-between">
                                <Col lg={4}>
                                  <h2 className="font-roboto mb30 heading">
                                    We're sure you have questions
                                  </h2>
                                </Col>
                                <Col lg={8}>
                                  <div className="section">
                                    <CustomAccordion items={faqs} />
                                  </div>
                                </Col>
                              </Row>
                            </Container>
                          </Fade>
                        </section>
                      </div>
                    </div>
                  </Frame>
                </div>
              )
            })}
          </>
        )}
      />
    </div>
  )
}

export default Awards2024
